import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
@Component({
    selector: 'app-contact-success-component',
    templateUrl: 'contact-success.component.html',
    styleUrls: ['contact-success.component.scss']
})
export class ContactSuccessComponent {
    constructor(
        public dialogRef: MatDialogRef<ContactSuccessComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        console.log(data);
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
