import { Component, OnInit, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PortfolioConstants } from './portfolio.constants';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  isOpen = false;
  projectList: Array<any> = [];
  constructor(
    private titleService: Title,
    private router: Router,
    private portfolioConstants: PortfolioConstants
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Portfolio - Syx');
    for (const eachPortfolio of this.portfolioConstants.portfolios) {
      this.projectList.push({
        id: eachPortfolio.id,
        title: eachPortfolio.title,
        description: eachPortfolio.subtitle,
        image_src: eachPortfolio.bannerImageUrl
      });
    }
  }

  redirectToDetailsPage(url, id) {
    const projectDetailsUrl = `${url}/${id}`;
    this.router.navigateByUrl(projectDetailsUrl);
  }
}
