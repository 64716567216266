import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ContactEndpointService } from '../services/contact-endpoint-service';
import { EnquiryRequest } from '../models/enquiry-request';
import { ContactSuccessComponent } from './contact-success.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private titleService: Title,
    private contactService: ContactEndpointService,
    private dialog: MatDialog
  ) {
    this.contactForm = this.fb.group({
      messageContent: new FormControl('', Validators.required),
      messageTitle: new FormControl('', Validators.required),
      messageSender: new FormControl('', Validators.required),
      senderEmail: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Contact - Syx');
  }

  sendEmail() {
    const contactFormCopy = this.contactForm.value;
    // const contactFormCopy = Object.assign({}, this.contactForm.value);
    const enquiryRequest: EnquiryRequest = {
      name: contactFormCopy.messageSender,
      email: contactFormCopy.senderEmail,
      subject: contactFormCopy.messageTitle,
      body: contactFormCopy.messageContent
    };
    return this.contactService.submitEnquiry(enquiryRequest).subscribe(
      success => {
        this.dialog.open(ContactSuccessComponent, {
          height: '',
          width: '',
          data: { name: this.contactForm.value.messageSender, title: this.contactForm.value.messageTitle }
        });
        this.contactForm.reset();
        this.contactForm.markAsPristine();
        this.contactForm.markAsUntouched();
      },
      error => {
        console.error(error);
        // TODO: notify user of error
      }
    );
  }
}
