import { Component, OnInit, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  clients = [];
  team = [];
  serviceList = ['Web Application', 'Mobile Application', 'Application Maintenance', 'Process Automation', 'Cloud Solutions'];
  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Home - Syx');
    this.team.push({
      name: 'Shalv',
      position: 'Business Development Manager',
      image_src: './assets/shalv.jpg',
    });
    this.team.push({
      name: 'Nicholas',
      position: 'Backend Lead',
      image_src: './assets/nic.jpg',
    });
    this.team.push({
      name: 'Ji Sheng',
      position: 'Frontend Lead',
      image_src: './assets/jisheng.jpg',
    });
    this.team.push({
      name: 'Jia Wen',
      position: 'Fullstack Developer',
      image_src: './assets/jiawen.jpg',
    });
    this.team.push({
      name: 'Zou Yun',
      position: 'Fullstack Developer',
      image_src: './assets/zouyun.jpg',
    });
    // this.team.push({
    //   name: 'Gelei',
    //   position: 'Security Consultant',
    //   image_src: './assets/gelei.jpg',
    // });
    this.clients.push({ image_src: './assets/sutd.png' });
    this.clients.push({ image_src: './assets/blyss_logo.png' });
  }

}
