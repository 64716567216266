<div class="container">
  <div class="section first-section">
    <img [src]="bannerImageUrl" class="banner-image">
    <div class="banner-info">
      <h1 class="mat-display-1">{{portfolioTitle}}</h1>
      <span class="mat-h2">{{portfolioSubtitle}}</span>
      <div class="app-download-container">
        <img [src]="iosBadgeUrl" style="margin:6%;width:27%;margin-left: 0; margin-right: 0;">
        <img [src]="androidBadgeUrl" style="width: 33%; height: 40%;">
      </div>
    </div>
  </div>
  <h2 class="mat-h1" style="margin-bottom:2rem;">Main Features</h2>
  <div class="section second-section">
    <div class="feature-container">
      <!-- ONLY USED FOR DESKTOP VIEW -->
      <div class="left-column">
        <div *ngFor="let feature of leftFeatureList" class="each-feature">
          <mat-icon>{{feature.icon}}</mat-icon>
          <h4 class="mat-h2">{{feature.feature}}</h4>
          <span class="mat-h3">{{feature.description}}</span>
        </div>
      </div>
      <img [src]="mainScreenshotUrl" alt="Main Screenshot of the App" class="main-feature-image">
      <!-- ONLY USED FOR PHONE VIEW -->
      <div class="column-phone">
        <div *ngFor="let feature of leftFeatureList" class="each-feature">
          <mat-icon>{{feature.icon}}</mat-icon>
          <h4 class="mat-h2">{{feature.feature}}</h4>
          <span class="mat-h3">{{feature.description}}</span>
        </div>
      </div>
      <div class="right-column">
        <div *ngFor="let feature of rightFeatureList" class="each-feature">
          <mat-icon>{{feature.icon}}</mat-icon>
          <h4 class="mat-h2">{{feature.feature}}</h4>
          <span class="mat-h3">{{feature.description}}</span>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <h2 class="mat-h1" style="margin-bottom:2rem;">App Screenshots</h2>
  <div class="section">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
      <div ngxSlickItem *ngFor="let screenshot of screenshotList" style="display:flex; justify-content:center;">
        <img [src]="screenshot.image_src" class="screenshot-image">
      </div>
    </ngx-slick-carousel>
  </div>
  <app-footer></app-footer>
</div>
