import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  isToggled: boolean;
  isPristine: boolean;
  constructor() {
    this.isPristine = true;
  }

  ngOnInit() {
  }

  toggle() {
    this.isPristine = false;
    this.isToggled = !this.isToggled;
  }

}
