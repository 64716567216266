import { Portfolio } from './portfolio.model';
import { Injectable } from "@angular/core";

@Injectable()
export class PortfolioConstants {
    portfolios: Array<Portfolio> = [
        {
            id: 1,
            title: 'SUTD Alumni Mobile App',
            subtitle: `Register for events, update personal details, book facilities and give to SUTD, all in one
            app. For alumni, by alumni.`,
            bannerImageUrl: './assets/alumni-app-banner.png',
            detailBannerImageUrl: './assets/sutd-alumni.png',
            mainScreenshotUrl: './assets/sutd-alumni-home.png',
            leftFeatureList: [
                {
                    feature: 'Give Back',
                    description: 'Contribute to the alma mater',
                    icon: 'attach_money'
                },
                {
                    feature: 'Attend',
                    description: 'Register and pay for events on the go',
                    icon: 'calendar_today'
                }
            ],
            rightFeatureList: [
                {
                    feature: 'Personalise',
                    description: 'Update all your personal particulars',
                    icon: 'edit'
                },
                {
                    feature: 'Facilities',
                    description: 'Book for facilities in school',
                    icon: 'place'
                }
            ],
            screenshotList: [
                { image_src: './assets/project-screenshots/alumni-app/sutd-alumni-login.png' },
                { image_src: './assets/project-screenshots/alumni-app/sutd-alumni-faceid.png' },
                { image_src: './assets/project-screenshots/alumni-app/sutd-alumni-home.png' },
                { image_src: './assets/project-screenshots/alumni-app/sutd-alumni-facilities.png' },
                { image_src: './assets/project-screenshots/alumni-app/sutd-alumni-contact.png' },
                { image_src: './assets/project-screenshots/alumni-app/sutd-alumni-giveback.png' },
                { image_src: './assets/project-screenshots/alumni-app/sutd-alumni-profile.png' },
                { image_src: './assets/project-screenshots/alumni-app/sutd-alumni-events.png' }
            ]
        },
        {
            id: 2,
            title: 'Blyss',
            subtitle: `Blyss is a mobile app for the distressed, making it easier for
            those suffering from depression and other mental health conditions to seek help.`,
            bannerImageUrl: './assets/blyss-banner.png',
            detailBannerImageUrl: './assets/blyss-detail.png',
            mainScreenshotUrl: './assets/blyss-home.png',
            leftFeatureList: [
                {
                    feature: 'Instant Messaging',
                    description: 'Talk to a professional counsellor real time',
                    icon: 'chat'
                },
                {
                    feature: 'Guided Paths',
                    description: 'Mental health self-assessments curated by professionals',
                    icon: 'navigation'
                }
            ],
            rightFeatureList: [
                {
                    feature: 'Confidential',
                    description: 'All personal data is stored securely on device',
                    icon: 'lock'
                },
                {
                    feature: 'Journal Entries',
                    description: 'Keep track of moods and feelings daily',
                    icon: 'book'
                }
            ],
            screenshotList: [
                { image_src: './assets/project-screenshots/blyss/blyss-landing.png' },
                { image_src: './assets/project-screenshots/blyss/blyss-home.png' },
                { image_src: './assets/project-screenshots/blyss/blyss-chat.png' },
                { image_src: './assets/project-screenshots/blyss/blyss-paths.png' },
                { image_src: './assets/project-screenshots/blyss/blyss-goals-wait.png' },
                { image_src: './assets/project-screenshots/blyss/blyss-goals.png' },
                { image_src: './assets/project-screenshots/blyss/blyss-exam.png' },
                { image_src: './assets/project-screenshots/blyss/blyss-time.png' },
            ]
        }
    ];
}
