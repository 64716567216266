import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PortfolioConstants } from '../portfolio/portfolio.constants';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  bannerImageUrl = '';
  iosBadgeUrl = './assets/ios-badge.svg';
  androidBadgeUrl = './assets/google-play-badge.png';
  imageList = [];
  screenshotList = [];
  leftFeatureList = [];
  rightFeatureList = [];
  portfolioTitle = '';
  portfolioSubtitle = '';
  mainScreenshotUrl = '';
  slideConfig = {};
  private subscription: any;
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private portfolioConstants: PortfolioConstants
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Portfolio - Syx');
    // if phone width
    if (window.innerWidth <= 500) {
      this.slideConfig = {
        'dots': true,
        'slidesToShow': 1,
        'slidesToScroll': 1
      };
    } else {
      this.slideConfig = {
        'dots': true,
        'slidesToShow': 4,
        'slidesToScroll': 4
      };
    }
    this.subscription = this.route.params.subscribe(params => {
      // (+) converts string 'id' to a number
      const currentPortfolio = this.portfolioConstants.portfolios.find(x => x.id === +params['id']);
      if (currentPortfolio) {
        this.bannerImageUrl = currentPortfolio.detailBannerImageUrl;
        this.mainScreenshotUrl = currentPortfolio.mainScreenshotUrl;
        this.portfolioTitle = currentPortfolio.title;
        this.portfolioSubtitle = currentPortfolio.subtitle;
        this.screenshotList = currentPortfolio.screenshotList;
        this.rightFeatureList = currentPortfolio.rightFeatureList;
        this.leftFeatureList = currentPortfolio.leftFeatureList;
      }
    });
  }

  slickInit(e) {
    console.log('slick initialized');
  }
}
