<!-- THIS IS FOR VIEW > PHONE -->
<div class="navbar">
  <img src="./assets/syx-menu.svg" style="height:4.5rem;">
  <div class="link-container">
    <a class="mat-h4" routerLink="/">HOME</a>
    <a class="mat-h4" routerLink="/about">ABOUT</a>
    <a class="mat-h4" routerLink="/portfolio">PORTFOLIO</a>
    <a class="mat-h4" routerLink="/contact">CONTACT</a>
  </div>
</div>
<!-- THIS IS FOR VIEW <= PHONE -->
<div class="navbar-phone-container" [class.collapse]="isToggled" [class.uncollapse]="!isToggled && !isPristine">
  <div class="top-section">
    <img src='./assets/syx-menu.svg' alt="logo">
  </div>
  <div class="navbar-content">
    <a class="mat-body" routerLink="/home" routerLinkActive="active" (click)="toggle()">HOME</a>
    <a class="mat-body" routerLink="/about" routerLinkActive="active" (click)="toggle()">ABOUT</a>
    <a class="mat-body" routerLink="/portfolio" routerLinkActive="active" (click)="toggle()">PORTFOLIO</a>
    <a class="mat-body" routerLink="/contact" routerLinkActive="active" (click)="toggle()">CONTACT</a>
    <a class="mat-body" (click)="toggle()" style="position: absolute; bottom: 0;">
      <mat-icon>close</mat-icon>
    </a>
  </div>
</div>
<div class="navbar-phone">
  <a (click)="toggle()">
    <mat-icon id="menu">menu</mat-icon>
  </a>
  <img src='./assets/syx.svg' alt="logo" id="logo">
</div>
