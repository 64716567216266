<div class="container">
  <div class="section">
    <img src="./assets/sutd-building.png" alt="sutd" style="width:100%; height:100%; margin-top:2rem; margin-bottom:2rem;">
  </div>
  <div class="section">
    <div class="column" id="story-container">
      <h1 class="mat-display-1">Our Story</h1>
      <h4 class="mat-h2">Syx is a design and development studio based in Singapore. We specialise in ideating customised
        solutions for our customers to enhance user experience
        in the crowded web and app market space. We work closely with our clients to design a user journey that allows
        them to achieve their envisioned application and better communicate their brand value.
      </h4>
      <br>
      <h4 class="mat-h2 work-together-link" routerLink="/contact">Let's work together</h4>
    </div>
    <div class="column" style="width:25%;">
      <h1 class="mat-display-1">Services</h1>
      <h4 class="mat-h2" *ngFor="let service of serviceList">{{service}}</h4>
    </div>
  </div>
  <hr>
  <h1 class="mat-display-1" style="margin-top:5rem;">Our Team</h1>
  <div class="section">
    <div class="team-container">
      <div *ngFor="let member of team" class="portrait-container">
        <img [src]="member.image_src" style="width:100%;">
        <h1 class="mat-h1" style="text-align:center; margin-top: 1rem;">{{member.name}}</h1>
        <h1 class="mat-h2" style="text-align:center; width:100%;">{{member.position}}</h1>
      </div>
    </div>
  </div>
  <hr>
  <h1 class="mat-display-1" style="margin-top:5rem;">Clients</h1>
  <div class="section" style="justify-content: start;">
    <div *ngFor="let client of clients" class="image-container">
      <img [src]="client.image_src">
    </div>
  </div>
  <hr>
  <app-footer></app-footer>
</div>
