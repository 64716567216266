import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../app.constants';
import { EnquiryRequest } from '../models/enquiry-request';

@Injectable({
  providedIn: 'root',
})
export class ContactEndpointService {
  constructor(
    private http: HttpClient
  ) { }

  submitEnquiry(enquiry: EnquiryRequest) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    const url = ENDPOINTS.enquiries;
    return this.http.post(url, JSON.stringify(enquiry), httpOptions);
  }
}
