<div class="footer">
  <div class="column">
    <div class="logo-container">
      <img src='./assets/syx.svg' style="width:2rem;margin-right: 1.5rem;">
      <span class="mat-h1" style="margin:0; letter-spacing: 10px;">SYX</span>
    </div>
  </div>
  <div class="column">
    <h6 class="mat-h3">8 Somapah Road | Singapore 487372</h6>
    <h6 class="mat-h3">syxstudiosg@gmail.com</h6>
  </div>
</div>
