import { PortfolioConstants } from './portfolio/portfolio.constants';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MenuComponent } from './menu/menu.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HttpClientModule } from '@angular/common/http';
import { ContactSuccessComponent } from './contact/contact-success.component';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  entryComponents: [
    ContactSuccessComponent
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    PortfolioComponent,
    MenuComponent,
    ContactSuccessComponent,
    ProjectDetailsComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    NgProgressModule,
    NgProgressHttpModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    HttpClientModule
  ],
  providers: [
    PortfolioConstants
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
