import { Component, OnInit, HostBinding, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as THREE from 'three';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('rendererContainer', { static: true }) rendererContainer: ElementRef;

  renderer = new THREE.WebGLRenderer({ alpha: true });
  scene = null;
  camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 1, 10000);

  SEPARATION = 100;
  AMOUNTX = 80;
  AMOUNTY = 50;

  mouseX = 0;
  mouseY = 0;
  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;
  particles = null;
  particle = null;
  count = 0;

  isUIUXLoaded = false;
  isMachineLearningLoaded = false;

  constructor(private titleService: Title) {

    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 10000);
    this.camera.position.z = 800;
    this.particles = new Array();

    const PI2 = Math.PI * 2;
    const material = new THREE.MeshBasicMaterial({ color: 0x000000 });

    let i = 0;
    const geometry = new THREE.BoxGeometry(1, 1, 1);

    for (let ix = 0; ix < this.AMOUNTX; ix++) {
      for (let iy = 0; iy < this.AMOUNTY; iy++) {
        this.particle = this.particles[i++] = new THREE.Mesh(geometry, material);
        this.particle.position.x = ix * this.SEPARATION - ((this.AMOUNTX * this.SEPARATION) / 2);
        this.particle.position.z = iy * this.SEPARATION - ((this.AMOUNTY * this.SEPARATION) / 2);
        this.scene.add(this.particle);
      }
    }
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setClearColor(0xFFFFFF, 0);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  onDocumentMouseMove(event) {
    this.mouseX = event.clientX - this.windowHalfX;
    this.mouseY = event.clientY - this.windowHalfY;
  }

  machineLearningLoaded() {
    this.isMachineLearningLoaded = true;
  }

  uiuxLoaded() {
    this.isUIUXLoaded = true;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    const parent = document.getElementById('three-container');
    const width = parent.clientWidth;
    const height = parent.clientHeight;
    this.renderer.setSize(width, height);
  }

  ngOnInit() {
    this.titleService.setTitle('Home - Syx');
  }

  ngAfterViewInit() {
    const parent = document.getElementById('three-container');
    const width = parent.clientWidth;
    const height = parent.clientHeight;
    this.renderer.setSize(width, height);
    this.renderer.domElement.style.display = 'absolute';
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    this.animate();
  }

  animate() {
    window.requestAnimationFrame(() => this.animate());
    this.render();
  }

  render() {
    this.camera.position.x += (this.mouseX - this.camera.position.x) * .05;
    this.camera.position.y += (-this.mouseY - this.camera.position.y) * .05;
    // change this if want to raise to a higher view, set y of scene to more negative value
    this.scene.position.y = -500;
    this.camera.lookAt(this.scene.position);
    let i = 0;
    for (let ix = 0; ix < this.AMOUNTX; ix++) {
      for (let iy = 0; iy < this.AMOUNTY; iy++) {
        this.particle = this.particles[i++];
        // this determines the waves
        this.particle.position.y = (Math.cos((ix + this.count) * 0.3) * 50) +
          (Math.cos((iy + this.count) * 0.5) * 50);
        this.particle.scale.x = this.particle.scale.y = (Math.sin((ix + this.count) * 0.3) + 1) * 4 +
          (Math.sin((iy + this.count) * 0.5) + 1) * 4;
      }
    }
    this.renderer.render(this.scene, this.camera);
    this.count += 0.1;
  }

}
