<div class="container">
  <div class="section">
    <h1 class="mat-display-1" style="margin-bottom:1rem;">Let's Work Together</h1>
    <span class="mat-h2">Please provide us with a little information about your project and we will get back to you shortly. </span>
  </div>
  <form [formGroup]="contactForm" style="margin-top:2rem;">
    <div class="email-section">
      <div class="top-row">
        <mat-form-field>
          <input matInput placeholder="Name" formControlName="messageSender">
          <mat-error>Name is
            <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="email" placeholder="Email" formControlName="senderEmail">
          <mat-error>Email is
            <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="second-row">
        <mat-form-field>
          <input matInput placeholder="Title" formControlName="messageTitle">
          <mat-error>Title is
            <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="last-row">
        <mat-form-field>
          <textarea matInput autosize minRows="20" placeholder="Message" formControlName="messageContent"></textarea>
          <mat-error>
            Message is
            <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <button mat-raised-button type="button" (click)="sendEmail()">Send</button>
      <br>
      <br>
    </div>
  </form>
  <app-footer></app-footer>
</div>
